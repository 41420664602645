import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'components/Intl';
import Grid from '@mui/material/Grid';
import Modal from '@core-ui/modal';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@core-ui/toggle';
import { IToggleButton } from '@core-ui/toggle/types';
import DialogContent from '@mui/material/DialogContent';
import { useAppSelector } from '@/hooks/useAppSelector';
import { PAYMENT_REQUEST_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import CreateEditRequestContent from './components/CreateEditRequestContent';
import FormSubmitButton from './components/FormSubmitButton';
import Title from './components/Title';
import selector from './selector';

const buttons: IToggleButton[] = Object.values(PAYMENT_REQUEST_TYPE_ENUM).map((variant) => ({
  value: variant,
  content: <FormattedMessage id={variant} />,
}));

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const CreateEditRequestModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const { formInitial } = useAppSelector(selector);
  const [selected, setSelected] = useState(PAYMENT_REQUEST_TYPE_ENUM.DEFAULT);

  useEffect(() => {
    if (formInitial) {
      setSelected(formInitial.request_type);
    }
  }, [formInitial]);

  const setSelectedTab: ToggleButtonGroupProps['onChange'] = (event, newAlignment) => {
    if (newAlignment) {
      setSelected(newAlignment);
    }
  };

  return (
    <Modal
      open={opened}
      maxWidth="lg"
      onClose={onClose}
      title={
        <Title selected={selected} type={<FormattedMessage id={`label.${formInitial?.id ? 'edit' : 'create'}`} />} />
      }
    >
      <DialogContent>
        <Grid container wrap="nowrap" marginBottom={7} justifyContent="space-between">
          <ToggleButtonGroup value={selected} onChange={setSelectedTab} buttons={buttons} />

          <FormSubmitButton />
        </Grid>

        <CreateEditRequestContent selected={selected} formInitial={formInitial} />
      </DialogContent>
    </Modal>
  );
};

export default CreateEditRequestModal;
