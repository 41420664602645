import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import { ScrollMemory } from '@core-ui/redux-router';
import { COLOURS } from '@core-ui/styles';
import Switch from '@core-ui/switch';
import { buildURL } from '@core-ui/url';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ArrowSquareOut, FileText, Info, Receipt, X } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { DATE_FORMAT } from '@/app/consts/app';
import { ROUTES } from '@/app/consts/routes';
import { formatTime, parseISO } from '@/app/utils/dates';
import NoDataContainer from '@/components/NoDataContainer';
import StatusBadge from '@/components/StatusBadge';
import { DocTypePublicNameEnum } from '@/generated';
import history from '@/history';
import { useAppSelector } from '@/hooks/useAppSelector';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import {
  SINGLE_DOCUMENT_INFO_TAB_DIVIDER_WIDTH,
  SINGLE_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH,
  SINGLE_DOCUMENT_INFO_TAB_SELECTORS_WIDTH,
  SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH,
} from '@/pages/backoffice/Ledger/consts';
import {
  deleteErpCategory,
  deleteRequest,
  deleteWallet,
  updateErpCategory,
  updateRequest,
  updateVisibilityInAnalytics,
  updateWallet,
} from '@/pages/backoffice/Ledger/LedgerDocumentInfo/actions';
import { getNoDataContainerData } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/selector';
import { LedgerDocumentInfoSkeleton } from '@/pages/backoffice/Ledger/skeletons';
import { DETAIL_REQUEST_TABS } from '@/pages/backoffice/Requests/SingleRequest/types';
import selector from './selector';
import useStyles from './styles';

const SCROLLABLE_CONTAINER_ID = 'ledger-document-info';

const LedgerDocumentInfo: FC = () => {
  const { item, boatId, clientCostArticlesOptions, walletOptions, requestOptions, loading } = useAppSelector(selector);

  const dispatch = useDispatch();
  const styles = useStyles({ loading });

  const isDocumentReceipt = item?.document_type === DocTypePublicNameEnum.RECEIPT;

  const handleErpChange = (value: number) => {
    if (item) {
      dispatch(updateErpCategory({ documentId: item.id, categoryId: String(value) }));
    }
  };

  const handleWalletChange = (value: number) => {
    if (item) {
      dispatch(updateWallet({ documentId: item.id, walletId: value }));
    }
  };

  const handleRequestChange = (value: number) => {
    if (item) {
      dispatch(updateRequest({ documentId: item.id, requestId: value }));
    }
  };

  const handleRedirectToWallet = () => {
    if (item) {
      history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}/${item.wallet_id}`));
    }
  };

  const handleRedirectToRequest = () => {
    if (item) {
      history.push(
        buildURL(
          `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${item.request_id}/${DETAIL_REQUEST_TABS.ABOUT}`
        )
      );
    }
  };

  const handleChangeVisibilityInAnalytics = () => {
    if (item) {
      dispatch(
        updateVisibilityInAnalytics({
          documentId: item.id,
          hide: !item.is_hide_from_analytics,
        })
      );
    }
  };

  const handleDeleteErpCategory = () => {
    if (item) {
      dispatch(deleteErpCategory({ documentId: item.id }));
    }
  };

  const handleDeleteWallet = () => {
    if (item) {
      dispatch(deleteWallet({ documentId: item.id }));
    }
  };

  const handleDeleteRequest = () => {
    if (item) {
      dispatch(deleteRequest({ documentId: item.id }));
    }
  };

  let documentTypeIcon = <FormattedMessage id="label.no_info" />;
  if (item) {
    documentTypeIcon = isDocumentReceipt ? (
      <>
        <SvgIcon>
          <Receipt />
        </SvgIcon>

        <Typography>
          <FormattedMessage id="document_type.receipt" />
        </Typography>
      </>
    ) : (
      <>
        <SvgIcon>
          <FileText />
        </SvgIcon>

        <Typography>
          <FormattedMessage id="document_type.invoice" />
        </Typography>
      </>
    );
  }

  const location = item?.location ?? <FormattedMessage id="label.no_info" />;

  // TODO: на бэке дикое непостоянство с датами, форматы где какие. Нужно чтобы их все привели к unix timestamp
  // issue_date приходит в виде ISO строки, а created_at в виде форматированной строки с точками вместо слешей
  const issueDate = item?.issue_date ? (
    formatTime(parseISO(item.issue_date), { dateFormat: DATE_FORMAT })
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const paymentDate = item?.paid_at ? (
    formatTime(parseISO(item.paid_at), { dateFormat: DATE_FORMAT })
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const createdAt = item?.created_at ? item.created_at.replace(/\./g, '/') : <FormattedMessage id="label.no_info" />;

  // TODO: удалить этот игнор когда бэк добавит типизацию параметра status схемы DocumentSearchResult
  // @ts-ignore
  const statusBadge = item?.status ? <StatusBadge status={item.status} /> : <FormattedMessage id="label.no_info" />;

  const uploadedByName = [item?.created_by_first_name, item?.created_by_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <NoDataContainer
        sx={styles.container}
        selector={getNoDataContainerData}
        direction="column"
        loader={<LedgerDocumentInfoSkeleton />}
        id={SCROLLABLE_CONTAINER_ID}
      >
        <Grid container direction="column" gap={4} paddingBottom={16}>
          <Grid item container wrap="nowrap">
            <Grid item sx={styles.rowText}>
              <Typography color="text.secondary">
                <FormattedMessage id="label.document_type" />
              </Typography>
            </Grid>

            <Grid item container gap={1} width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
              {documentTypeIcon}
            </Grid>
          </Grid>

          <Grid item container wrap="nowrap">
            <Grid item sx={styles.rowText}>
              <Typography color="text.secondary">
                <FormattedMessage id="label.location" />
              </Typography>
            </Grid>

            <Grid item width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
              {location}
            </Grid>
          </Grid>

          <Grid item container wrap="nowrap">
            <Grid item sx={styles.rowText}>
              <Typography color="text.secondary">
                <FormattedMessage id="label.issue_date" />
              </Typography>
            </Grid>

            <Grid item width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
              {issueDate}
            </Grid>
          </Grid>

          {!isDocumentReceipt && (
            <Grid item container wrap="nowrap">
              <Grid item sx={styles.rowText}>
                <Typography color="text.secondary">
                  <FormattedMessage id="label.payment_date" />
                </Typography>
              </Grid>

              <Grid item width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
                {paymentDate}
              </Grid>
            </Grid>
          )}

          {!isDocumentReceipt && (
            <Grid item container wrap="nowrap">
              <Grid item sx={styles.rowText}>
                <Typography color="text.secondary">
                  <FormattedMessage id="label.status" />
                </Typography>
              </Grid>

              <Grid item width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
                {statusBadge}
              </Grid>
            </Grid>
          )}

          <Grid item container wrap="nowrap">
            <Grid item sx={styles.rowText}>
              <Typography color="text.secondary">
                <FormattedMessage id="label.uploaded_by" />
              </Typography>
            </Grid>

            <Grid item width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
              {uploadedByName}
            </Grid>
          </Grid>

          <Grid item container wrap="nowrap">
            <Grid item sx={styles.rowText}>
              <Typography color="text.secondary">
                <FormattedMessage id="label.uploaded_date" />
              </Typography>
            </Grid>

            <Grid item width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
              {createdAt}
            </Grid>
          </Grid>

          <Grid item container wrap="nowrap">
            <Grid item sx={styles.rowText}>
              <Typography color="text.secondary">
                <FormattedMessage id="label.description" />
              </Typography>
            </Grid>

            <Grid item width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
              {/* todo: такого поля ещё нет на бэке */}
              {/* {item?.description} */}
              <FormattedMessage id="label.no_info" />
            </Grid>
          </Grid>

          <Grid item marginTop={8} width={SINGLE_DOCUMENT_INFO_TAB_DIVIDER_WIDTH}>
            <Divider />
          </Grid>

          <Grid item container marginTop={8} wrap="nowrap">
            <Grid item sx={styles.rowText}>
              <Typography fontWeight="semiBold">
                <FormattedMessage id="label.settings" />
              </Typography>

              <Typography color="text.secondary">
                <FormattedMessage id="label.document_settings" />
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              gap={4}
              width={SINGLE_DOCUMENT_INFO_TAB_SELECTORS_WIDTH}
              wrap="nowrap"
            >
              <Grid item container gap={2}>
                <Grid item width={SINGLE_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH}>
                  <Autocomplete
                    label={<FormattedMessage id="label.erp_category" />}
                    loading={loading}
                    disabled={loading || !item}
                    value={item?.erp_category}
                    onChange={handleErpChange}
                    options={clientCostArticlesOptions}
                    dataTestId="ledger-single-document-erp-category-autocomplete"
                  />
                </Grid>

                {item?.erp_category && (
                  <Grid item container width="auto" alignItems="center" gap={2} wrap="nowrap">
                    <Tooltip
                      title={<FormattedMessage id="label.delete_erp_category_from_document" />}
                      placement="top"
                      arrow
                    >
                      <Box>
                        <Button
                          compact
                          variant="text"
                          color="blue"
                          size="small"
                          disabled={loading || !item}
                          onClick={handleDeleteErpCategory}
                          icon={
                            <SvgIcon htmlColor={loading || !item ? undefined : COLOURS.HUE_STRAWBERRY.HUE_100}>
                              <X />
                            </SvgIcon>
                          }
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>

              <Grid item container gap={2} wrap="nowrap">
                <Grid item width={SINGLE_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH}>
                  <Autocomplete
                    label={<FormattedMessage id="label.wallet" />}
                    loading={loading}
                    disabled={loading || !item}
                    value={item?.wallet_id}
                    onChange={handleWalletChange}
                    options={walletOptions}
                    dataTestId="ledger-single-document-wallet-autocomplete"
                  />
                </Grid>

                {item?.wallet_id && (
                  <Grid item container width="auto" alignItems="center" gap={2} wrap="nowrap">
                    <Tooltip title={<FormattedMessage id="label.delete_document_from_wallet" />} placement="top" arrow>
                      <Box>
                        <Button
                          compact
                          variant="text"
                          color="blue"
                          size="small"
                          disabled={loading || !item}
                          onClick={handleDeleteWallet}
                          icon={
                            <SvgIcon htmlColor={loading || !item ? undefined : COLOURS.HUE_STRAWBERRY.HUE_100}>
                              <X />
                            </SvgIcon>
                          }
                        />
                      </Box>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="label.open_wallet" />} placement="top" arrow>
                      <Box>
                        <Button
                          compact
                          variant="text"
                          color="blue"
                          size="small"
                          disabled={loading || !item}
                          onClick={handleRedirectToWallet}
                          icon={<ArrowSquareOut />}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>

              <Grid item container gap={2} wrap="nowrap">
                <Grid item width={SINGLE_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH}>
                  <Autocomplete
                    label={<FormattedMessage id="label.request" />}
                    loading={loading}
                    disabled={loading || !item}
                    value={item?.request_id}
                    onChange={handleRequestChange}
                    options={requestOptions}
                    dataTestId="ledger-single-document-request-autocomplete"
                  />
                </Grid>

                {item?.request_id && (
                  <Grid item container width="auto" alignItems="center" gap={2} wrap="nowrap">
                    <Tooltip title={<FormattedMessage id="label.delete_document_from_request" />} placement="top" arrow>
                      <Box>
                        <Button
                          compact
                          variant="text"
                          color="blue"
                          size="small"
                          disabled={loading || !item}
                          onClick={handleDeleteRequest}
                          icon={
                            <SvgIcon htmlColor={loading || !item ? undefined : COLOURS.HUE_STRAWBERRY.HUE_100}>
                              <X />
                            </SvgIcon>
                          }
                        />
                      </Box>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="label.open_request" />} placement="top" arrow>
                      <Box>
                        <Button
                          compact
                          variant="text"
                          color="blue"
                          size="small"
                          disabled={loading || !item}
                          onClick={handleRedirectToRequest}
                          icon={<ArrowSquareOut />}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>

              <Grid item marginTop={2} width="auto" maxWidth={SINGLE_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
                <Divider />
              </Grid>

              <Grid item container gap={2} marginTop={2} wrap="nowrap" minHeight={40}>
                <Grid
                  item
                  container
                  gap={2}
                  width="auto"
                  sx={styles.visibilityInAnalyticsContainer}
                  onClick={handleChangeVisibilityInAnalytics}
                  wrap="nowrap"
                >
                  <Switch
                    size="medium"
                    disabled={loading || !item}
                    checked={!item?.is_hide_from_analytics}
                  />

                  <Grid item>
                    <FormattedMessage id="label.count_document_in_analytics" />
                  </Grid>
                </Grid>

                <Grid item>
                  <Tooltip title={<FormattedMessage id="tooltip.count_document_in_analytics" />} placement="top" arrow>
                    <SvgIcon>
                      <Info />
                    </SvgIcon>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </NoDataContainer>
    </>
  );
};

export default LedgerDocumentInfo;
