import { COLOURS } from '@core-ui/styles';
import { WIDTH_COMMENTS_SIDEBAR } from '@/pages/backoffice/Inbox/consts';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  comments: {
    width: WIDTH_COMMENTS_SIDEBAR,
    height: '100%',
    backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
  },
  commentsHeader: {
    borderBottom: `1px solid ${COLOURS.HUE_COCONUT.HUE_172}`,
  },
  commentText: {
    color: COLOURS.TEXT_CONTROL.MAIN,
    whiteSpace: 'pre',
    margin: 0,
    paddingTop: 4,
  },
  iframe: {
    padding: '16px 0 0 16px',
  },
});
