import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import {
  AddQuotesToCompareModalData,
  ApproveDocumentModalData,
  CreateEditContractModalData,
  CreateEditRequestModalData,
  CreateEditWalletModalData,
  DeclineDocumentModalData,
  DeleteContractModalData,
  DeleteInboxDocumentData,
  DeleteInvoiceModalData,
  DeleteRequestModalData,
  DeleteSingleContractTransactionModalData,
  DeleteWalletModalData,
  DeleteWalletTransactionModalData,
  ImageCarouselModalData,
  RejectRequestModalData,
  SendForApprovalModalData,
  SendToXeroItemModalData,
  UploadInvoicesModalData,
  UploadQuotesModalData,
  UseQuoteAsInvoiceModalData,
  SendToErpRequestInvoicesBatchModalData,
  SendToErpRequestInvoicesModalData,
} from '@/pages/backoffice/Modals/command';
import { handleActions } from 'redux-actions';
import { FILE_UPLOAD_TYPE, INVOICE_FILE_UPLOAD_TYPE_ENUM } from '../Requests/types';
import * as actions from './actions';

export interface IREQ_FILE_INFO {
  files: File[];
  docType: INVOICE_FILE_UPLOAD_TYPE_ENUM | FILE_UPLOAD_TYPE;
}

export type IModalsData =
  | AddQuotesToCompareModalData
  | ApproveDocumentModalData
  | CreateEditContractModalData
  | CreateEditRequestModalData
  | CreateEditWalletModalData
  | DeclineDocumentModalData
  | DeleteContractModalData
  | DeleteInboxDocumentData
  | DeleteInvoiceModalData
  | DeleteRequestModalData
  | DeleteSingleContractTransactionModalData
  | DeleteWalletModalData
  | DeleteWalletTransactionModalData
  | ImageCarouselModalData
  | RejectRequestModalData
  | SendForApprovalModalData
  | SendToXeroItemModalData
  | UploadInvoicesModalData
  | UploadQuotesModalData
  | UseQuoteAsInvoiceModalData
  | SendToErpRequestInvoicesBatchModalData
  | SendToErpRequestInvoicesModalData;

export interface IModalsState {
  data: Nullable<IModalsData>;
  opened: boolean;
  loading: boolean;
}

const defaultState = Immutable<IModalsState>({
  data: null,
  opened: false,
  loading: false,
});

export default handleActions<typeof defaultState>(
  {
    [actions.toggleModalLoading.toString()]: (state) => {
      return state.set('loading', !state.loading);
    },
    // side effect for resetting loading state on modal close
    [actions.closeBackofficeModal.toString()]: (state) => {
      return state.set('loading', false);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
