import { useQuery } from '@core-ui/redux-router';
import { HEIGHT_SIZE } from '@core-ui/styles';
import { updateQuery } from '@core-ui/url';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import history from '@/history';
import { LEDGER_DOCUMENT_TAB_ENUM, LEDGER_TOP_PADDING } from '@/pages/backoffice/Ledger/consts';
import LedgerDocumentHeader from '@/pages/backoffice/Ledger/LedgerDocumentHeader';
import LedgerDocumentInfo from '@/pages/backoffice/Ledger/LedgerDocumentInfo';
import LedgerDocumentLines from '@/pages/backoffice/Ledger/LedgerDocumentLines';
import { LedgerDocumentQueryParams } from '@/pages/backoffice/Ledger/types';
import React, { FC, useEffect } from 'react';

const containerStyle: SxProps<Theme> = {
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight + LEDGER_TOP_PADDING}px))`,
  overflow: 'hidden',
};

const LedgerDocumentContent: FC = () => {
  const { tab } = useQuery<LedgerDocumentQueryParams>();

  useEffect(() => {
    if (!tab) {
      updateQuery<LedgerDocumentQueryParams>({
        tab: LEDGER_DOCUMENT_TAB_ENUM.LINES,
      });
    }
  }, []);

  let content: JSX.Element;

  switch (tab) {
    case LEDGER_DOCUMENT_TAB_ENUM.LINES:
      content = <LedgerDocumentLines />;
      break;
    case LEDGER_DOCUMENT_TAB_ENUM.INFORMATION:
      content = <LedgerDocumentInfo />;
      break;
    default:
      content = <LedgerDocumentLines />;
  }

  return (
    <Container maxWidth="xl" sx={containerStyle}>
      <LedgerDocumentHeader />

      {content}
    </Container>
  );
};

export default LedgerDocumentContent;
